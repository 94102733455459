import sendMarriedDoc from '../../../../api/creditPlatform/sendMarriedDoc';
import editDeal from '../../../../api/deals/editDeal';
import uploadDocumentToDeal from '../../../../api/deals/uploadDocumentToDeal';
import HookForm from '../../../../components/HookForm/HookForm';
import DocsInputNew from '../../../../components/Inputs/DocsInput/DocsInputNew';
import {
  BANKS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_MARRIAGE_CONTRACT,
  DOCUMENT_TYPES_OUT_OF_COURT_CAR_SALE,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
} from '../../../../constants';
import alert from '../../../../helpers/alert';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import isFileSizeSmallerThan from '../../../../utils/validateFileSize';

const NotarialDocuments = ({ backHandler, loading, deal, chosenBankAppealId, setLoading, onSuccess, bankId }) => {
  const onNotarialDocumentsSubmit = async (values) => {
    setLoading(true);

    if (!values['согласие']) {
      alert.error('Необходимо загрузить согласие супруга (супруги) на внесудебную реализацию авто');
      setLoading(false);
      return;
    }

    if(!isFileSizeSmallerThan(values['согласие'], 1)) {
      alert.error('Размер файла согласия супруга (супруги) не должен превышать 1 МБ');
      setLoading(false);
      return;
    }

    await uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_SPOUSE_CONSENT,
      file: values['согласие'],
    });
    if (values['доверенность']) {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        file: values['доверенность'],
      });
    }
    if (values['Заявление на внесудебную реализацию авто']) {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_OUT_OF_COURT_CAR_SALE,
        file: values['Заявление на внесудебную реализацию авто'],
      });
    }
    if (values['Брачный контракт']) {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_MARRIAGE_CONTRACT,
        file: values['Брачный контракт'],
      });
    }
    if(bankId != BANKS.FORTE) {
      sendMarriedDoc({ appealId: chosenBankAppealId })
      .then(() => {
        onSuccess(values);
      })
      .catch(() => {
        alert.error('Не удалось отправить согласие супруга (супруги) на внесудебную реализацию авто');
        setLoading(false);
      });
    } else {
      onSuccess(values);
    }
  };

  return (
    <div>
      <HookForm
        title={'Документы'}
        hint="Проконсультируйте клиента какие именно доверенности и согласия надо сделать. Нужно чтобы клиент запрашивал равнозначность доверенности, это необходимо для того, чтобы можно было выписать доверенность у любого нотариуса.   Так же нужно описать на кого нужно сделать доверенность на постановку на учет. Выписать доверенность на регистрационные действия нужно на ФИО, ИИН человека."
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            text: 'Согласие супруга (супруги) на внесудебную реализацию авто',
            label: 'Загрузить документ',
            name: 'согласие',
            id: 'Согласие супруга (супруги) на внесудебную реализацию авто',
            component: DocsInputNew,
            required: true,
          },
          {
            text: 'Заявление на внесудебную реализацию авто',
            label: 'Загрузить документ',
            name: 'Заявление на внесудебную реализацию авто',
            id: 'Заявление на внесудебную реализацию авто',
            component: DocsInputNew,
          },
          {
            text: 'Брачный контракт',
            label: 'Загрузить документ',
            name: 'Брачный контракт',
            id: 'Брачный контракт',
            component: DocsInputNew,
          },
          {
            text: 'Доверенность постановки на учет',
            label: 'Загрузить документ',
            name: 'доверенность',
            id: 'Доверенность постановки на учет',
            component: DocsInputNew,
          },
          {
            label: 'Номер доверенности',
            name: 'powerOfAttorneyNumber',
          },
        ]}
        onSubmit={onNotarialDocumentsSubmit}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default NotarialDocuments;
