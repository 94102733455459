import HookForm from "../../../../components/HookForm/HookForm";

const EditCarGosNumber = ({ onSubmit, loading, backHandler }) => {
  return (
    <div>
      <HookForm
        title={'Документы'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            label: 'ГРНЗ',
            name: 'grnz',
            id: 'grnz',
            rules: {
              required: true,
            },
          },
          {
            label: 'Техпаспорт',
            name: 'texNumber',
            id: 'texNumber',
            rules: {
              required: true,
            },
          },
        ]}
        onSubmit={onSubmit}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default EditCarGosNumber;
