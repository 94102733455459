import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { DOCUMENTS } from '../../constants/columns';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import icon from '../../assets/buttonIcons/addDeal.svg';
import getDealDocuments from '../../api/deals/getDealDocuments';
import { useParams } from 'react-router-dom';
import HookForm from '../../components/HookForm/HookForm';
import SelectDocumentType from '../../components/Selects/SelectDocumentType';
import AddButton from '../../components/AddButton/AddButton';
import { textStyles } from '../../constants/textStyles';
import DeleteButton from '../../components/DeleteButton/DeleteButton';
import Button from '../../components/Button/Button';
import DocsInputNew from '../../components/Inputs/DocsInput/DocsInputNew';
import uploadDocumentToDeal from '../../api/deals/uploadDocumentToDeal';
import Loader from '../../components/Loader/Loader';
import LoaderInline from '../../components/Loader/LoaderInline';
import HalykGetGuaranteeLetter from './DealCreate/HalykFlow/HalykGetGuaranteeLetter';
import getDealCard from '../../api/deals/getDealCard';
import getCreditAppeal from '../../api/deals/getCreditAppeal';
import { BANKS, DOCUMENT_TYPES_GUARANTEE_LETTER, DOCUMENT_TYPES_PAYMENT_ORDER } from '../../constants';
import CheckBankStatus from './DealCreate/CommonBankSteps/CheckBankStatus';
import cancelCreditAppeal from '../../api/creditPlatform/forte/cancelCreditAppeal';
import extractErrorMessage from '../../utils/extractErrorMessage';

const formStyles = {
  document: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 1fr 500px`, alignItems: 'flex-end' },
};

const columns = DOCUMENTS;

const DealDocs = () => {
  const row = () => [
    {
      component: SelectDocumentType,
      name: 'fileTypeId',
    },
    {
      component: DocsInputNew,
      name: 'file',
      label: 'Файл документа',
      text: 'Файл документа',
      id: Math.random(),
    },
    {
      component: DeleteButton,
      name: 'delete',
      onClick: (e, index) => {
        removeRow(e, index);
      },
    },
  ];
  const { id } = useParams();
  const [form, setForm] = useState([row()]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [documents, setDocuments] = React.useState([]);
  const table = useTable(
    {
      columns,
      data: documents,
      //TODO: make pagination here
      initialState: { pageIndex: 0, pageSize: 1000000 },
    },
    usePagination
  );

  const init = async () => {
    setLoadingPage(true)
    let array = [];
    await getDealDocuments({ id: id })
      .then((res) => {
        // setDocument([...res.data]);
        res.data.forEach((item) => {
          array.push({
            id: item.id,
            url: item.url,
            type: item.type,
          });
        });
      })
      .finally(() => setLoadingPage(false));
    setDocuments([...array]);
    setForm([row()]);
  };

  React.useEffect(() => {
    init();
  }, []);

  const addRow = () => {
    setForm([...form, row()]);
  };

  const removeRow = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const newForm = form;
    newForm.slice(index, 1);
    setForm(newForm);
  };

  const onSubmit = () => {
    setLoading(true);
    const fields = form.map((i) => i.map((i) => i.value?.value || i.value));
    Promise.all(
      fields.map((field) =>
        uploadDocumentToDeal({
          dealId: id,
          fileTypeId: field[0],
          file: field[1],
        })
      )
    ).finally(() => {
      init();
      setLoading(false);
    });
  };

  const [chosenBankId, setChosenBankId] = useState(null);
  const [chosenBankAppealId, setChosenBankAppealId] = useState(null);
  const [chunkId, setChunkId] = useState(null);

  useEffect(() => {
    getDealCard({ id }).then((res) => {
      setChunkId(res?.data?.chunkId);
      getCreditAppeal({ chunkId: res?.data?.chunkId }).then((res) => {
        const chosenBank = res?.data?.appeals?.find((appeal) => appeal?.selected);
        setChosenBankId(chosenBank?.bank?.id);
        setChosenBankAppealId(chosenBank?.id);
      });
    });
  });

  const showHalykGetGuaranteeLetterButton = useMemo(() => {
    const isGuaranteeLetterPresent = documents?.some((item) => item?.type?.id === DOCUMENT_TYPES_GUARANTEE_LETTER);
    return chosenBankId === BANKS.HALYK && !isGuaranteeLetterPresent;
  }, [chosenBankId, documents]);

  const isForteChosen = useMemo(() => {
    return chosenBankId === BANKS.FORTE;
  }, [chosenBankId]);

  const showForteUpdateButton = useMemo(() => {
    const isGuaranteeLetterPresent = documents?.some((item) => item?.typeId === DOCUMENT_TYPES_PAYMENT_ORDER);
    return chosenBankId === BANKS.FORTE && !isGuaranteeLetterPresent;
  }, [chosenBankId, documents]);

  
  const cancelForteAppeal = () => {
    cancelCreditAppeal(chosenBankAppealId)
      .then(() => {
        init();
        alert.success('Заявка успешно отменена');
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err) || 'Ошибка при отмене заявки');
      });
  };

  if (loading) {
    return (
      <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
        <LoaderInline />
      </div>
    );
  }

  return (
    <>
      {loadingPage ? (
        <Loader />
      ) : (
        <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
          <EmptyData
            title={`Документы к сделке (${documents.length})`}
            subtitle={'К этой сделке не найдено ни одного документа'}
            action={null}
            icon={icon}
            table={table}
            data={documents}
          />

          <div style={{
            display: 'flex',
            gap: 20
          }}>
          {showHalykGetGuaranteeLetterButton && (
            <HalykGetGuaranteeLetter appealId={chosenBankAppealId} onSuccess={init} />
          )}
          {showForteUpdateButton && <CheckBankStatus setLoading={setLoading} chunkId={chunkId} bankId={chosenBankId} onSuccess={init} text="Платежное поручение" />}
          {isForteChosen && <Button onClick={cancelForteAppeal} fill>Отменить заявку в банке Форте</Button>}
          </div>


          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20 }}>
            <p style={textStyles.formTitle}>Документы сделки</p>
          </div>
          {form.map((row, index) => (
            <HookForm grid={formStyles.document} inputs={row} style={{ margin: 0 }} index={index} />
          ))}

          <AddButton text="Добавить еще документ" onClick={addRow} />

          <div
            style={{
              marginTop: 20,
            }}
          >
            <Button text="Сохранить" fill onClick={onSubmit} />
          </div>
        </div>
      )}
    </>
  );
};

export default DealDocs;
