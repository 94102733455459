import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// components
import Header from '../../../components/Header/Header';
import Form from '../../../components/Form/Form';
import Button from '../../../components/Button/Button';
import Breadcrumbs from '../../../components/Breadcrumbs';

// icons
import icon from '../../../assets/sidebar/report.svg';
import HookForm from '../../../components/HookForm/HookForm';
import Select from '../../../components/Select/Select';
import Datepicker from '../../../components/Inputs/Datepicker/Datepicker';
import DownloadFileLink from '../../../components/DownloadFileLink/DownloadFileLink';
import alert from '../../../helpers/alert';

const formStyles = {
  report: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'c c b b'` },
};

// Список отчетов
const reportOptions = [
  { id: 1, title: 'Список успешных сделок' },
  { id: 2, title: 'Список сделок с одобрением банков' },
  { id: 3, title: 'Список отказанных сделок' },
  { id: 4, title: 'Клиенты, успешно прошедшие БМГ проверку' },
  { id: 5, title: 'Клиенты, прошедшие БМГ тест и получившие отказ' },
  { id: 6, title: 'Клиенты, которым дважды отказали все банки' },
];

const formData = [
  {
    component: Datepicker,
    name: 'from',
    label: 'Отчетный период с',
    placeholder: 'дд-мм-гггг',
    rules: {
      required: true,
    },
  },
  {
    component: Datepicker,
    name: 'to',
    label: 'Отчетный период до',
    placeholder: 'дд-мм-гггг',
    rules: {
      required: true,
    },
  },
  {
    component: Select,
    label: 'Тип отчета',
    name: 'reportType',
    options: reportOptions.map((r) => ({ value: r.id, label: r.title })),
    placeholder: 'Выберите отчет',
    rules: {
      required: true,
    },
  },
]

const ReportCreate = () => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post('https://admin.doscar.kz/api/report/download', {
        id: values?.reportType?.value,
        title: values?.reportType?.label,
        date: { from: values?.from, to: values?.to },
      });

      window.open(response.data.url?.replace("test-", ""), "_blank");
    } catch (error) {
      alert.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header title="Новый отчет" icon={icon} />
      <Breadcrumbs navigate={'/reports'} link={'Мои отчеты'} currentPage={'Новый отчет'} />
      <HookForm
        inputs={formData}
        title={'Отчет'}
        grid={formStyles.report}
        onSubmit={handleDownload}
        border
        action="Сформировать отчет"
        loading={loading}
      />
    </>
  );
};

export default ReportCreate;
