import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import FormHeading from '../../../../components/Typography/FormHeading';
import {
  DEAL_STATUS_CAR_DELIVERY,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_PAYMENT_ORDER,
  DOCUMENT_TYPES_POLES,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
} from '../../../../constants';
import CarDelivery from '../CommonBankSteps/CarDelivery';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import ContractSign from '../CommonBankSteps/ContractSign';
import CarRegistration from '../CommonBankSteps/CarRegistration';
import carRegistration from '../../../../api/cars/registration';
import CheckBankStatus from '../CommonBankSteps/CheckBankStatus';
import setStep from '../../../../api/deals/setStep';
import PaymentStatus from '../CommonBankSteps/PaymentStatus';
import NotarialDocuments from '../CommonBankSteps/NotarialDocuments';
import editDeal from '../../../../api/deals/editDeal';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import extractErrorMessage from '../../../../utils/extractErrorMessage';
import EditCarGosNumber from '../CommonBankSteps/EditCarGosNumber';
import editCar from '../../../../api/cars/editCar';
import alert from '../../../../helpers/alert';

const EurazFlow = ({ step, deal, goBack, update, documents, cars, bankId, chosenBankAppealId }) => {
  const [loading, setLoading] = useState(false);

  const onRegistrationSubmit = async (values) => {
    try {
      setLoading(true);
      await carRegistration({
        bankId,
        chunkId: deal?.chunkId,
        carId: cars?.[0]?.id,
        reg_num: '',
      });
      await setStep({ dealId: deal?.id, step: DEAL_STEPS.EURAZ.CAR_DELIVERY });
      update && update();
    } catch (e) {
      alert.error(e);
      setLoading(false);
    }
  };

  const onPaymentSubmit = (values) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
        DOCUMENT_TYPES_PAYMENT_ORDER,
      ]),
    })
      .then(async () => {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.EURAZ.NOTARIAL_VERIFICATION });

        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCarGosNumber = async (values) => {
    try {
      setLoading(true);
      await editCar(cars?.[0]?.id, {
        grnz: values['grnz'],
        texNumber: values['texNumber'],
      });

      await editDeal({
        id: deal?.id,
        statusId: DEAL_STATUS_WAIRING_REGISTRATION,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
          DOCUMENT_TYPES_PAYMENT_ORDER,
        ]),
      });
      await setStep({ dealId: deal?.id, step: DEAL_STEPS.EURAZ.AUTO_REGISTRATION });
      update && update();
    } catch (e) {
      alert.error('Ошибка при сохранении данных автомобиля');
    } finally {
      setLoading(false);
    }
  };

  const onNotarialDocumentsSuccess = (values) => {
    editDeal({
      id: deal?.id,
      dealId: deal?.id,
      statusId: DEAL_STATUS_WAIRING_REGISTRATION,
      step: DEAL_STEPS.EURAZ.AUTO_REGISTRATION,
      power_of_attorney_number: values?.powerOfAttorneyNumber
        ? values?.powerOfAttorneyNumber
        : deal?.power_of_attorney_number,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
        DOCUMENT_TYPES_PAYMENT_ORDER,
      ]),
    })
      .then(() => {
        update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoaderInline />;
  }

  if (step === DEAL_STEPS.EURAZ.SIGN_CONTRACT) {
    return (
      <ContractSign
        deal={deal}
        documents={documents}
        cars={cars}
        dealStepAfterSign={DEAL_STEPS.EURAZ.WAITING_FOR_PAYMENT}
        setLoading={setLoading}
        update={update}
      />
    );
  }
  if (step === DEAL_STEPS.EURAZ.WAITING_FOR_PAYMENT) {
    return (
      <>
        <PaymentStatus
          onSubmit={onPaymentSubmit}
          documents={documents}
          deal={deal}
          chunkId={deal?.chunkId}
          setLoading={setLoading}
          loading={loading}
          bankId={bankId}
        />
        <div style={{ marginTop: 20 }}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }

  if (step === DEAL_STEPS.EURAZ.NOTARIAL_VERIFICATION) {
    return (
      <>
        <NotarialDocuments
          documents={documents}
          loading={loading}
          onSuccess={onNotarialDocumentsSuccess}
          deal={deal}
          setLoading={setLoading}
          chosenBankAppealId={chosenBankAppealId}
          bankId={bankId}
        />
        <div style={{ marginTop: 20 }}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.EURAZ.EDIT_CAR_GOS_NUMBER) {
    return (
      <>
        <EditCarGosNumber onSubmit={editCarGosNumber} loading={loading}/>
      </>
    );
  }

  if (step === DEAL_STEPS.EURAZ.AUTO_REGISTRATION) {
    return (
      <>
        <CarRegistration
          onSuccess={onRegistrationSubmit}
          deal={deal}
          setLoading={setLoading}
          loading={loading}
          statusOption={[
            {
              value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
              label: 'Ожидает постановки ТС на учет',
            },
            {
              value: DEAL_STATUS_CAR_DELIVERY,
              label: 'Машина поставлена на учет',
            },
          ]}
        />
        <div style={{ marginTop: 20 }}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }

  if (step === DEAL_STEPS.EURAZ.CAR_DELIVERY) {
    return <CarDelivery deal={deal} loading={loading} setLoading={setLoading} onSuccess={update} />;
  }
};

export default EurazFlow;
