import React, { useState } from 'react';
import SelectCreditPeriod from '../../../components/Selects/SelectCreditPeriod';
import createNumberMask from '../../../utils/createNumberMask';
import HookForm from '../../../components/HookForm/HookForm';
import SelectFamilyStatus from '../../../components/Selects/SelectFamilyStatus';
import DealCreateDocs from './DealCreateDocs';
import { DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT, MARITAL_STATUS_MARRIED } from '../../../constants';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';
import DealCreateAgreement from './DealCreateAgreement';
import { MASK } from '../../../constants/mask';
import DocsInputNew from '../../../components/Inputs/DocsInput/DocsInputNew';
import alert from '../../../helpers/alert';
import { textStyles } from '../../../constants/textStyles';
import Hint from '../../../components/Hint';
import numberMask from '../../../utils/numberMask';
import Checkbox from '../../../components/Inputs/Checkbox/Checkbox';
import SelectContactType from '../../../components/Selects/SelectContactType';
import SelectResitentType from '../../../components/Selects/SelectResitentType';
import SelectClientDocumentType from '../../../components/Selects/SelectClientDocumentTypes';
import SelectClientIssuer from '../../../components/Selects/SelectClientDocumentType';
import SelectWorkStatus from '../../../components/Selects/SelectWorkStatus';
import BanksPicker from '../../../components/Credit/BanksPicker';
import Select from '../../../components/Select/Select';

const CREDIT_TYPE_OPTIONS = [
  {
    label: 'Aннуитетный',
    value: 'A',
  },
  {
    label: 'Дифференцированный',
    value: 'D',
  },
];

const DealCreateCreditAppeal = ({
  deal,
  documents,
  onSubmit,
  orders,
  agreementModal,
  setAgreementModal,
  backHandler,
  sendFormLoading,
}) => {
  const [files, setFiles] = React.useState({
    UDV: null,
    passportRK: null,
    clientPhoto: null,
    incomeCertificate: null,
    workCertificate: null,
    legalConfirm: null,
  });
  const inputs = [
    {
      label: 'Вид платежа',
      name: 'creditType',
      component: Select,
      options: CREDIT_TYPE_OPTIONS,
      rules: {
        required: true,
      },
      placeholder: "Выберите из списка",
    },
    {
      label: 'Первоначальный взнос',
      name: 'initialFee',
      placeholder: 'Введите первоначальный взнос',
      mask: numberMask,
      hint: 'Для получения положительного ответа и выгодных условий от Форте Банка требуется первоначальный взнос не менее 15%.',
      rules: {
        valueAsNumber: true,
        validate: (value) => {
          value = value?.replace(/\s/g, '');
          value = parseInt(value);
          console.log(deal?.sum);
          let error = true;
          if (value === 0) {
            return error;
          }
          const carPrice = deal?.sum;
          // if (value < (carPrice * 0.1)?.toFixed(0)) {
          //   error = `Минимальная сумма взноса 10% от стоимости автомобиля`;
          // } else
          if (value > carPrice) {
            error = 'Сумма взноса не может превышать стоимость автомобиля';
          } else {
            error = true;
          }

          return error;
        },
      },
    },
    {
      label: 'Срок кредитования, месяцев',
      name: 'period',
      component: SelectCreditPeriod,
      rules: {
        required: true,
      },
    },
    {
      label: 'Основной доход',
      name: 'incomeMain',
      rules: {
        required: true,
      },
      mask: numberMask,
    },
    // {
    //   label: 'Дополнительный заработок',
    //   name: 'IncomeAddConfirmed',
    //   mask: numberMask,
    // },
    {
      label: 'Место работы',
      name: 'workName',
      rules: {
        required: true,
        validate: (value) => {
          if (value.length > 50) {
            return 'Максимальная допустимая длина поля "Место работы" - 50 символов';
          }

          return true;
        },
      },
    },
    {
      label: 'Адрес работы',
      name: 'workAddress',
      rules: {
        required: true,
      },
    },
    {
      component: SelectWorkStatus,
      name: 'workStatus',
      hint: 'Обязательно для Банк ЦентрКредит',
    },
    {
      label: 'Семейное положение',
      name: 'MaritalStatus',
      component: SelectFamilyStatus,
      rules: {
        required: true,
      },
    },
    {
      label: 'Доход супруги/супруга',
      name: 'SpouseIncomeAmount',
      mask: numberMask,
      hide: (values) => !(values === MARITAL_STATUS_MARRIED),
      dependency: `MaritalStatus`,
      dependencyAccessor: (obj) => obj?.value,
    },
    {
      label: 'Количество детей',
      name: 'childrensCount',
      mask: numberMask,
    },
    {
      label: 'Номер телефона компании, в которой работаете',
      name: 'companyPhone',

      type: 'phone',
      mask: MASK.PHONE,

      hint: 'Номер телефона компании нужен только для Евразийского банка',
      rules: {
        required: true,
        validate: (value) => {
          value = value.replace(/[^0-9.]/g, '');
          return value.length === 11;
        },
      },
    },
    {
      component: () => {
        return <p style={{ ...textStyles.formTitle, marginTop: 20 }}>Контактное лицо</p>;
      },
      name: 'Контактное лицо',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
    {
      label: 'Фамилия',
      name: 'contactPersonLastName',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
      rules: {
        required: true,
      },
    },
    {
      label: 'Номер телефона',
      name: 'contactPersonPhone',
      type: 'phone',
      mask: MASK.PHONE,
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
      rules: {
        required: true,
        validate: (value) => {
          value = value.replace(/[^0-9.]/g, '');
          return value.length === 11;
        },
      },
    },
    {
      component: SelectContactType,
      name: 'contactPersonType',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
      rules: {
        required: true,
      },
    },
    {
      component: SelectResitentType,
      name: 'residentBool',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
      rules: {
        required: true,
      },
    },
    {
      component: SelectClientIssuer,
      label: 'Орган выдачи документа',
      name: 'documentGivedBy',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
      rules: {
        required: true,
      },
    },

    {
      component: () => {
        return <p style={{ ...textStyles.formTitle, marginTop: 20 }}>Контактное лицо 2</p>;
      },
      name: 'Контактное лицо2',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
    {
      label: 'Фамилия',
      name: 'contactPersonLastName2',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
    {
      label: 'Номер телефона',
      name: 'contactPersonPhone2',
      type: 'phone',
      mask: MASK.PHONE,
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
    {
      component: SelectContactType,
      name: 'contactPersonType2',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
    {
      component: SelectResitentType,
      name: 'residentBool2',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
    {
      component: SelectClientIssuer,
      label: 'Орган выдачи документа',
      name: 'documentGivedBy2',
      hide: (value) => !value,
      dependencyAccessor: (obj) => obj,
      dependency: `workStatus`,
    },
  ];

  const attachFile = (file, key) => {
    setFiles((prev) => ({
      ...prev,
      [key]: file,
    }));
  };

  const [documentsForm, setDocumentsForm] = React.useState([]);
  const [subsidy, setSubsidy] = React.useState(false);
  const [grace, setGrace] = React.useState(false);
  const [incomeConfirmation, setincomeConfirmation] = React.useState(false);
  const [form, setForm] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [selectedBanks, setSelectedBanks] = React.useState([]);
  const [vehicle_status, setVehicle_status] = React.useState(false);
  const handleSubmit = (values) => {
    // if (!files['UDV'] && !files['passportRK']) {
    //   return alert.error('Прикрепите один из документов: УДВ или паспорт РК');
    // }
    
    if (!files['UDV']) {
      return alert.error('Прикрепите документ: УДВ');
    }
    if (!files['clientPhoto']) {
      return alert.error('Прикрепите документ: Фотография клиента');
    }

    // if (!files['incomeCertificate']) {
    //   return alert.error('Прикрепите справку о доходах');
    // }
    if (values?.workStatus?.value == '9' && !files['workCertificate']) {
      return alert.error('Для статуса клиента "Клиент с выпиской БВУ" обязательный документ "Выписка со счета"');
    }
    if (['3', '6', '10']?.includes(values?.workStatus?.value) && !files['incomeCertificate']) {
      return alert.error('Для текущего статуса клиента обязательный документ "Справка о доходах"');
    }

    if (values?.companyPhone?.replace(/[^\d]/g, '') === deal?.client?.phone?.replace(/[^\d]/g, '')) {
      return alert.error('Номер телефона компании и клиента совпадают');
    }

    if (values?.contactPersonPhone?.replace(/[^\d]/g, '') === deal?.client?.phone?.replace(/[^\d]/g, '')) {
      return alert.error('Номер телефона контактного лица и клиента совпадают');
    }
    if (values?.contactPersonPhone2?.replace(/[^\d]/g, '') === deal?.client?.phone?.replace(/[^\d]/g, '')) {
      return alert.error('Номер телефона контактного лица и клиента совпадают');
    }
    setLoading(true);
    try {
      const contactPersons = [];

      if (values?.contactPersonLastName) {
        contactPersons?.push({
          contactPersonLastName: values?.contactPersonLastName,
          contactPersonPhone: values?.contactPersonPhone?.replace(/[^\d]/g, ''),
          contactPersonType: values?.contactPersonType?.value,
          residentBool: values?.residentBool?.value,
          documentGivedBy: values?.documentGivedBy == 1 ? 'MINISTRY_OF_THE_INTERIOR' : 'MINISTRY_OF_JUSTICE',
        });
      }
      if (values?.contactPersonLastName2) {
        contactPersons?.push({
          contactPersonLastName: values?.contactPersonLastName2,
          contactPersonPhone: values?.contactPersonPhone2?.replace(/[^\d]/g, ''),
          contactPersonType: values?.contactPersonType2?.value,
          residentBool: values?.residentBool2?.value,
          documentGivedBy: values?.documentGivedBy2 == 1 ? 'MINISTRY_OF_THE_INTERIOR' : 'MINISTRY_OF_JUSTICE',
        });
      }

      const payload = {
        ...values,
        creditType: values?.creditType?.value,
        initialFee: values?.initialFee ? +values?.initialFee?.replace(/\s/g, '') : 0,
        incomeMain: +values?.incomeMain?.replace(/\s/g, ''),
        // IncomeAddConfirmed: +values?.IncomeAddConfirmed?.replace(/\s/g, ''),
        familyStatus: values?.familyStatus?.value,
        period: +values?.period?.value,
        graceDuration: +values?.period?.value,
        grace,
        incomeConfirmation: !incomeConfirmation,
        orderIds: orders?.map((item) => item?.value),
        dealId: deal?.id,
        chunkId: deal?.chunkId,
        workStatus: values?.workStatus?.label,
        workStatusId: values?.workStatus?.value,
        subsidy,
        contactPersons: contactPersons,
        bankIds: selectedBanks,
        vehicle_status: vehicle_status ? 3 : 1,
      };
      // setLoading(false);
      // return console.log(payload)
      setForm(payload);
      onSubmit(payload, documentsForm, files).finally(() => setLoading(false));
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: 50 }}>
      {/* <DealCreateDocs
        title="Согласие на обработку персональных данных"
        deal={deal}
        documents={documents?.filter((item) => item?.typeId === DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT)}
        setDocumentsForm={setDocumentsForm}
        onSubmit={() => {}}
      /> */}
      <HookForm
        inputs={inputs}
        title="Уточнение данных клиента"
        subtitle={() => {
          return (
            <div style={{ marginTop: 15, display: 'flex', gap: 10 }}>
            <Checkbox
              label="Субсидия"
              name="subsidy"
              id="subsidy"
              onChange={() => setSubsidy(!subsidy)}
              value={subsidy}
            />
            <Checkbox
              label="Рассрочка"
              name="grace"
              id="grace"
              onChange={() => setGrace(!grace)}
              value={grace}
            />
            <Checkbox
              label="Без подтверждения дохода"
              name="incomeConfirmation"
              id="incomeConfirmation"
              onChange={() => setincomeConfirmation(!incomeConfirmation)}
              value={incomeConfirmation}
            />
            <Checkbox
              label="Первичная регистрация"
              name="vehicle_status"
              id="vehicle_status"
              onChange={() => setVehicle_status(!vehicle_status)}
              value={vehicle_status}
            />
            </div>
          );
        }}
        grid={{ display: 'grid', gap: 20 }}
        onSubmit={handleSubmit}
        loading={loading || sendFormLoading}
        action={'Сохранить и продолжить'}
        customData={() => (
          <div
            style={{
              display: 'grid',
              gap: 20,
            }}
          >
            <p style={textStyles.formTitle}>
              Сканы документов
              <Hint text="Не все банки требуют предоставления сканов документов. Если клиенту необходимы ответы от всех банков, рекомендуется загрузить все документы." />
            </p>
            <DocsInputNew
              text="Письмо-подтверждение по VIN коду"
              label="Загрузить документ"
              id="vinApprovalDocumentу"
              value={files['vinApprovalDocument']}
              onChange={(file) => attachFile(file, 'vinApprovalDocument')}
            />

            <DocsInputNew
              text="УДВ"
              label="Загрузить документ"
              id="УДВ"
              value={files['UDV']}
              onChange={(file) => attachFile(file, 'UDV')}
              hint="УДВ и Паспорт РК обязательны для БЦК и Евразийского банка. Загрузите один из документов для подачи заявки в эти банк."
            />
            <DocsInputNew
              text="Паспорт РК"
              label="Загрузить документ"
              id="Паспорт РК"
              value={files['passportRK']}
              onChange={(file) => attachFile(file, 'passportRK')}
              hint="УДВ и Паспорт РК обязательны для БЦК и Евразийского банка. Загрузите один из документов для подачи заявки в эти банк."
            />
            {(inputs?.find((item) => item.name === 'IncomeAddConfirmed')?.value ||
              ['3', '6', '10']?.includes(inputs?.find((item) => item.name === 'workStatus')?.value?.value)) && (
              <DocsInputNew
                text="Справка/Выписка дохода"
                label="Загрузить документ"
                id="Справка/Выписка дохода"
                value={files['incomeCertificate']}
                onChange={(file) => attachFile(file, 'incomeCertificate')}
                hint="Обязательный документ для подачи заявки в Евразийский и Береке банк."
              />
            )}

            {inputs?.find((item) => item.name === 'workStatus')?.value?.value == '9' && (
              <DocsInputNew
                text="Выписка со счета"
                label="Загрузить документ"
                id="Выписка со счета"
                value={files['workCertificate']}
                onChange={(file) => attachFile(file, 'workCertificate')}
              />
            )}
            <DocsInputNew
              text="Фотография клиента"
              label="Загрузить документ"
              id="Фотография клиента"
              value={files['clientPhoto']}
              onChange={(file) => attachFile(file, 'clientPhoto')}
              hint="Обязательно для БЦК и Евразийского банка"
            />

            {/* <DocsInputNew
              text="Документ подтверждающий ИП"
              label="Загрузить документ"
              id="Документ подтверждающий ИП"
              value={files['legalConfirm']}
              onChange={(file) => attachFile(file, 'legalConfirm')}
            /> */}

            <BanksPicker onChange={setSelectedBanks} />
          </div>
        )}
        backHandler={backHandler}
      />
      <Modal
        isOpen={agreementModal}
        onRequestClose={() => setAgreementModal(false)}
        width="100%"
        contentStyle={{
          maxWidth: 600,
          height: 'max-content',
        }}
      >
        <DealCreateAgreement
          deal={deal}
          sendAppeal={() => onSubmit(form, documentsForm, files, true)}
          onClose={() => {
            setAgreementModal(false);
          }}
          sendFormLoading={sendFormLoading}
        />
      </Modal>
    </div>
  );
};

export default DealCreateCreditAppeal;
